import React, { useState, useEffect } from "react";
import { FormControlLabel, Switch } from "@mui/material";

export default function YesNoSelect({
  name,
  label,
  register,
  defaultValue = false,
  onChange,
}) {
  const [controlValue, setControlValue] = useState<boolean>(defaultValue);

  const handleChange = (e) => {
    setControlValue(e.target.checked);
    onChange(e);
  };

  useEffect(() => {
    setControlValue(defaultValue);
  }, [defaultValue]);

  return (
    <FormControlLabel
      sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
      control={
        <Switch
          {...register(name)}
          checked={controlValue}
          value={controlValue}
          onChange={handleChange}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
}
