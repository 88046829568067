import { combineReducers } from '@reduxjs/toolkit'
import app from 'modules/app'
import auth from 'modules/auth'
import appNavBar from 'modules/appNavBar'
import bdcDashboard from 'modules/bdcDashboard'
import bdcListView from 'modules/bdcListView'
import communicationDashboard from 'components/CommunicationDashboard/modules'
import dashboard from 'components/Dashboard/modules'
import repairOrderListView from 'components/RepairOrderListView/modules'
import login from 'modules/login'
import customerSearchView from 'modules/customerSearchView'
import user from 'modules/user'
import dealer from 'modules/dealer'
import estimateDialog from 'modules/estimateDialog'
import roAlerts from 'modules/roAlerts'
import roAlertAddEditDialog from 'components/RepairOrderAlertAddEditDialog/modules'
import roAlertDialog from 'components/RepairOrderAlertDialog/module'
import chat from 'components/ChatModule/modules'
import customersWithUnreadMessages from 'components/UnreadConversationsDialog/modules'
import customerMessages from 'modules/customerMessages'
import dealerSearch from 'modules/dealersSearchView'
import features from 'modules/features'
import dealerForm from 'modules/dealerForm'
import maintenanceScheduleDialog from 'modules/maintenanceScheduleDialog'
import reporting from 'components/Reporting/modules/reporting'
import dealerPickerSearch from 'modules/dealerPicker'

const rootReducer = combineReducers({
    app,
    auth,
    appNavBar,
    bdcDashboard,
    bdcListView,
    chat,
    communicationDashboard,
    dashboard,
    repairOrderListView,
    login,
    customerSearchView,
    user,
    dealer,
    estimateDialog,
    roAlerts,
    roAlertAddEditDialog,
    roAlertDialog,
    customersWithUnreadMessages,
    customerMessages,
    dealerSearch,
    features,
    dealerForm,
    maintenanceScheduleDialog,
    reporting,
    dealerPickerSearch
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer