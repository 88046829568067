import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import getDealer from "api/getDealer";
import getDealerFeatures from "api/getDealerFeatures";
import getDealerUsersBySearch from "api/getDealerUsers";
import getDmsDealerSettingByDealerID from "api/getDmsDealerSettingByDealerID";
import getFeatures from "api/getFeatures";
import getROAutoAlertTemplateByDealer from "api/getROAutoAlertTemplateByDealer";
import getWARecipientsByDealer from "api/getWARecipientsByDealer";
import { WarrantyAlertRecipient } from "api/types/WarrantyAlertRecipient";
import { Dealer } from "api/types/dealer";
import { DmsDealerSetting } from "api/types/dmsDealerSetting";
import { User } from "api/types/user";
import { IFeatureListItem } from "components/DealerForm/components/FeaturesList";
import { AppThunk } from "store";

export interface IDealerForm {
  dealer: Dealer;
  dealerFeatures: IFeatureListItem[];
  dmsDealerSetting: DmsDealerSetting;
  users: User[];
  waRecipients: WarrantyAlertRecipient[];
  isLoading: boolean;
  infoText: string;
  hasError: boolean;
}

let initialState: IDealerForm = {
  dealer: {
    EnableMPVI: true,
    EnableCRM: true,
    EnableCommunicationDashboard: true,
    AutoSendEstimateThresholdInMinutes: 15,
  } as Dealer,
  dealerFeatures: [],
  dmsDealerSetting: {
    DmsProviderID: 1,
    IsActive: true,
    IsCheckInSyncEnabled: true,
    IsIncrementalSyncEnabled: true,
  } as DmsDealerSetting,
  users: [],
  isLoading: true,
  infoText: ``,
  hasError: false,
  waRecipients: [],
};

const slice = createSlice({
  name: `dealerForm`,
  initialState,
  reducers: {
    setDataStart(state) {
      state = initialState;
    },
    setData(state, action: PayloadAction<IDealerForm>) {
      state.isLoading = false;
      state.dealer = { ...action.payload.dealer };
      state.dealerFeatures = [...action.payload.dealerFeatures];
      state.dmsDealerSetting = { ...action.payload.dmsDealerSetting };
      state.waRecipients = [...action.payload.waRecipients];
    },
    upsertFeatures(state, action) {
      const updatedFeatures = action.payload.map((x) => {
        const feature = state.dealerFeatures.find(
          (sf) => sf.FeatureID === x.FeatureID
        );
        return {
          ...feature,
          ID: feature?.ID ?? 0,
          DealerID: feature?.DealerID ?? 0,
          FeatureID: x.FeatureID,
          IsEnabled: x.IsEnabled,
        };
      });

      state.dealerFeatures = updatedFeatures;
    },
    setDealer(state, action: PayloadAction<Dealer>) {
      state.dealer = { ...state.dealer, ...action.payload };
    },
    setDmsSettings(state, action) {
      state.dmsDealerSetting = {
        ...state.dmsDealerSetting,
        DealerID: state.dealer.ID,
        DmsProviderID: action.payload.DmsProviderID,
        DealerCode: action.payload.DealerCode,
        IsActive: action.payload.IsActive,
        IsCheckInSyncEnabled: action.payload.IsCheckInSyncEnabled,
        IsIncrementalSyncEnabled: action.payload.IsIncrementalSyncEnabled,
      };
    },
    reset() {
      return initialState;
    },
    toggleLoading(state) {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    },
    setHasError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasError: action.payload,
      };
    },
    setUsers(state, action: PayloadAction<User[]>) {
      return {
        ...state,
        users: action.payload,
      };
    },
    upsertRecipient(state, action) {
      const { Index: _, ...recipient } = action.payload;

      action.payload.Index !== -1
        ? (state.waRecipients[action.payload.Index] = {
            ...recipient,
          } as WarrantyAlertRecipient)
        : state.waRecipients.push(recipient);
    },
    deleteRecipient(state, action) {
      state.waRecipients = state.waRecipients.filter(
        (x) => state.waRecipients.indexOf(x) !== action.payload
      );
    },
    updateROAutoAlertTemplate(state, action) {
      state.dealer.ROAlertTemplate = {
        ...state.dealer.ROAlertTemplate,
        StartTime: action.payload.StartTime,
        CutoffTime: action.payload.CutoffTime,
        DealerID: action.payload.DealerID,
        AfterCutoffTimeBehavior: action.payload.AfterCutoffTimeBehavior,
        FirstDeltaInMinutes: action.payload.FirstDeltaInMinutes,
        SecondDeltaInMinutes: action.payload.SecondDeltaInMinutes,
      };
    },
  },
});

export const {
  setDataStart,
  setData,
  reset,
  upsertFeatures,
  setDealer,
  setDmsSettings,
  toggleLoading,
  setHasError,
  setUsers,
  upsertRecipient,
  deleteRecipient,
  updateROAutoAlertTemplate,
} = slice.actions;

export default slice.reducer;

export const getData =
  (id?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setDataStart());
      let dealer = id ? await getDealer(id) : initialState.dealer;

      const features = await getFeatures();

      const dealerFeatures = id
        ? await getDealerFeatures(id)
        : initialState.dealerFeatures;

      const mappedList = features.map((feature) => {
        const dealerFeature = dealerFeatures.find(
          (df) => df.FeatureID === feature.ID
        );

        const isEnabled = () => {
          if (dealerFeature) return dealerFeature.IsEnabled;

          if (!dealerFeature && 
            feature.ID !== 3.03 && // auto-send MPVI estimate messages
            feature.ID !== 3.04 && // send warranty alert messages
            feature.ID !== 4.02) // auto-create RO alerts from template
            return true;
          else return false;
        };

        return {
          ID: dealerFeature?.ID,
          FeatureID: feature.ID,
          DealerID: dealerFeature?.ID,
          Name: feature.Name,
          Description: feature.Description,
          IsEnabled: isEnabled(),
        } as IFeatureListItem;
      });

      const dmsDealerSettings = id
        ? await getDmsDealerSettingByDealerID(id)
        : initialState.dmsDealerSetting;

      const waRecipients = id
        ? await getWARecipientsByDealer(id)
        : initialState.waRecipients;

      const roAutoAlertTemplate = id
        ? await getROAutoAlertTemplateByDealer(id)
        : null;

      dealer = {
        ...dealer,
        ROAlertTemplate: roAutoAlertTemplate,
      };

      const data: IDealerForm = {
        dealer: dealer,
        dealerFeatures: mappedList,
        dmsDealerSetting: dmsDealerSettings,
        users: [],
        waRecipients: waRecipients,
        isLoading: false,
        infoText: ``,
        hasError: true,
      };

      dispatch(setData(data));
    } catch (error) {
      throw new Error(
        `An error occured while attempting to retrieve dealer information: ${error}`
      );
    }
  };

export const getDealerUsersData =
  (dealerID: number, searchTerm: string): AppThunk =>
  async (dispatch) => {
    try {
      const users = await getDealerUsersBySearch(dealerID, searchTerm);
      dispatch(setUsers(users));
    } catch (error) {
      throw new Error(
        `An error occured while attempting to retrieve dealer users information: ${error}`
      );
    }
  };
