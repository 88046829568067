import { GridColDef } from "@mui/x-data-grid";
import { SoldItem } from "api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel";
import React from "react";
import DataTable from "../../../../DataTable";

export interface ITopSoldDataGridProps {
    data: SoldItem[] 
}

export default function TopSoldDataGrid({
    data
}: ITopSoldDataGridProps) {
    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          headerName: 'ID',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'serviceName',
          headerName: 'Top Sold Service',
          sortable: false,
          editable: false,
          flex: 1,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'totalSold',
          headerName: 'Total Sold',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .5,
          disableColumnMenu: true
        },
      ];

    const rows = data.map((topService, index) => {
      return {
        id: index,
        serviceName: topService.ItemDescription,
        totalSold: topService.TotalSold,
      }
    })

    return (
        <DataTable
          rows={rows}
          columns={columns}/>
    )
}