import { TextField } from "@mui/material";
import React from "react";
import { IMaskInput } from "react-imask";
import NumberFormat from "react-number-format";

export enum TextMaskedInputType {
  State,
}

interface TextMaskedInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  regex: RegExp;
  type: TextMaskedInputType;
}

interface NumericMaskedInputProps {
  name: string;
  label: string;
  register: any;
  error: boolean;
  format: string;
  pattern: RegExp;
  value?: string | null;
  patternMessage: string;
  helpText?: string | null;
  required?: boolean | false;
  handleChange: (event: { target: { name: string; value: string } }) => void;
}

const TextMaskedInput = React.forwardRef<HTMLElement, TextMaskedInputProps>(
  (props, ref: any) => {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={props.mask}
        definitions={{
          "#": props.regex,
        }}
        inputRef={ref}
        onAccept={(value: string) => {
          switch (props.type) {
            case TextMaskedInputType.State: {
              value = value.toUpperCase();
              break;
            }
          }

          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  }
);

const NumericMaskedInput = (props: NumericMaskedInputProps) => {
  return (
    <NumberFormat
      fullWidth
      mask={``}
      customInput={TextField}
      format={props.format}
      label={props.label}
      {...props.register(props.name, {
        required: props.required,
        pattern: {
          value: props.pattern,
          message: props.patternMessage,
        },
      })}
      onChange={props.handleChange}
      error={props.error}
      value={props.value}
      helperText={props.helpText}
      name={props.name}
    ></NumberFormat>
  );
};

export { TextMaskedInput, NumericMaskedInput };
