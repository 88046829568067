import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { IDealerFormParams } from "./types";
import { useAppDispatch, useAppSelector } from "store";
import { Box, Button, Tab, Tabs } from "@mui/material";
import FeaturesList from "./components/FeaturesList";
import Form from "./components/Form";
import { reset, toggleLoading } from "modules/dealerForm";
import pages from "pages";
import { useSubscribeFormData } from "./hooks/useSuscribeFormData";
import { TabPanel, tabOptionProps } from "./components/TabPanel";
import DmsDealerSettings from "./components/DmsDealerSettings";
import { Dealer } from "api/types/dealer";
import postDealer from "api/postDealer";
import AlertDialog, { IAlertDialogProps } from "./components/AlertDialog";
import { DealerFeature } from "api/types/dealerFeature";
import UsersTab from "./components/Users";
import WarrantyAlertRecipients from "./components/WARecipients";
import ROAutoAlertTemplate from "./components/roAutoAlertTemplate";
import { RepairOrderAutoAlertTemplate } from "api/types/repairOrderAutoAlertTemplate";
import { retrieveDealer } from "modules/dealer";

const DealerForm = ({ match }: IDealerFormParams) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const formRef = React.useRef<any>();
  const settingsRef = React.useRef<any>();

  useSubscribeFormData(match?.params?.id);

  const handleOnBack = () => {
    dispatch(reset());
    history.push(pages.DEALERS.route);
  };

  const {
    dealer,
    dealerFeatures: features,
    dmsDealerSetting,
    waRecipients,
    hasError,
  } = useAppSelector((state) => state.dealerForm);

  const {
    ID: dealerId
  } = useAppSelector((state) => state.dealer);

  const [stateError, setStateError] = useState<boolean>();

  useEffect(() => {
    setStateError(hasError);
  }, [hasError]);

  const handleOnSave = () => {
    if (value === 0) {
      let canSave = formRef.current.submitForm();
      if (!canSave) return;
      else onSaveData();
    } else {
      onSaveData();
    }
  };

  const [dialog, setDialog] = useState<IAlertDialogProps>({ open: false });

  const toggleDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const onSaveData = async () => {
    if (stateError) {
      setDialog({
        title: "Error",
        message: `Please, make sure that you've filled all the required fields.`,
        open: true,
        toggleDialog: () => toggleDialog(),
      });

      return;
    }

    const dealerFeatures: DealerFeature[] = features.map((x) => {
      const { Name: _, Description: __, ...dealerFeature } = x;
      return { ...(dealerFeature as DealerFeature) };
    });

    let payload: Dealer = {
      ...dealer,
      DealerFeatures: [...dealerFeatures],
      DmsDealerSettings: [{ ...dmsDealerSetting }],
      WarrantyAlertRecipients: [...waRecipients],
    };

    if (dealer.ROAlertTemplate?.DealerID)
      payload.ROAlertTemplate = {
        ...dealer.ROAlertTemplate,
      } as RepairOrderAutoAlertTemplate;

    dispatch(toggleLoading());
    await postDealer(payload)
      .then(handleOnBack)
      .catch((error) => {
        dispatch(toggleLoading());
        setDialog({
          title: "Error",
          message:
            error.Message ??
            `Something went wrong. Please try again or contact your administrator.`,
          open: true,
          toggleDialog: () => toggleDialog(),
        });
      });

    
    // In case we updated the settings for the dealer that the user is currently
    // logged in under as, we will need to reload the dealer and the dealer features
    // so certain features are toggled.
    dispatch(retrieveDealer(dealerId))
  };

  const handleChange = (event: React.SyntheticEvent, indexValue: number) => {
    if (value === 0 && indexValue !== value) {
      let canChange = formRef.current.submitForm();
      if (!canChange) return;
      else setValue(indexValue);
    } else {
      setValue(indexValue);
    }
  };

  return (
    <Box component={"div"}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          mb: 2,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Dealer Information" {...tabOptionProps(0)} wrapped />
          <Tab label="Features" {...tabOptionProps(1)} />
          <Tab label="DMS Dealer Settings" {...tabOptionProps(2)} />
          {dmsDealerSetting.IsBulkAdvisorsSyncCompleted && (
            <Tab label="Users" {...tabOptionProps(3)} />
          )}
          <Tab
            label="Warranty Alert Recipients"
            {...tabOptionProps(dmsDealerSetting.IsBulkAdvisorsSyncCompleted ? 4 : 3)}
          />
          <Tab
            label="Repair Order Auto Alert Template"
            {...tabOptionProps(dmsDealerSetting.IsBulkAdvisorsSyncCompleted ? 4 : 5)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Form ref={formRef} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FeaturesList />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DmsDealerSettings ref={settingsRef} />
        </TabPanel>
        {dmsDealerSetting.IsBulkAdvisorsSyncCompleted && (
          <TabPanel value={value} index={3}>
            <UsersTab setDialog={setDialog} />
          </TabPanel>
        )}
        <TabPanel
          value={value}
          index={dmsDealerSetting.IsBulkAdvisorsSyncCompleted ? 4 : 3}
        >
          <WarrantyAlertRecipients />
        </TabPanel>
        <TabPanel
          value={value}
          index={dmsDealerSetting.IsBulkAdvisorsSyncCompleted ? 5 : 4}
        >
          <ROAutoAlertTemplate />
        </TabPanel>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "130px",
          position: "fixed",
          bottom: "9%",
        }}
      >
        <Button onClick={handleOnSave} variant="contained" type="submit">
          Save
        </Button>
        <Button
          onClick={handleOnBack}
          variant="outlined"
          type="button"
          sx={{ mt: 1 }}
        >
          Back
        </Button>
      </Box>
      <AlertDialog {...dialog} />
    </Box>
  );
};

export default withRouter(DealerForm);
