import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit'
import getRepairOrderByID from 'api/getRepairOrderByID'
import setRepairOrderAttentionNeeded from 'api/setRepairOrderAttentionNeeded'
import { BaseCustomer } from 'api/types/customer'
import { CustomerEstimate } from 'api/types/CustomerEstimate'
import apiFetch from 'api/utils/APIFetch'
import { updateRepairOrder } from 'components/RepairOrderListView/modules'
import { AppThunk } from 'store'
import { MpviStatus } from 'types/repairOrder'
import { titleCase } from 'voca'

export interface IOpenPayload {
    repairOrderID: number
    roNumber: string
    mpviStatus: MpviStatus
    workbenchURL: string
    estimate?: CustomerEstimate
    customer: BaseCustomer
}

export interface IEstimateDialog {
    repairOrderID: number | undefined
    roNumber: string
    mpviStatus: MpviStatus
    customerName: string
    estimate: CustomerEstimate | undefined
    workbenchURL: string
    isLoading: boolean
    isSending: boolean
    isOpen: boolean
    isError: boolean
    infoText: string
}

let initialState: IEstimateDialog = {
    isLoading: false,
    isSending: false,
    isOpen: false,
    isError: false,
    infoText: ``,
    repairOrderID: undefined,
    roNumber: ``,
    customerName: ``,
    mpviStatus: MpviStatus.UNKNOWN,
    estimate: undefined,
    workbenchURL: ``,
}

const slice = createSlice({
    name: `estimateDialog`,
    initialState,
    reducers: {
        estimateDialogOpened(state, action: PayloadAction<IOpenPayload>) {
            const {
                repairOrderID,
                roNumber,
                mpviStatus,
                workbenchURL,
                estimate,
                customer
            } = action.payload

            if (estimate !== undefined) {
                state.estimate = estimate
                state.customerName = titleCase(`${customer.FullName}`)
            }
            state.repairOrderID = repairOrderID
            state.roNumber = roNumber
            state.mpviStatus = mpviStatus
            state.workbenchURL = workbenchURL
            state.isOpen = true
        },
        sendTextStart(state) {
            state.isSending = true
        },
        sendTextFinish: () => initialState,
        sendTextFailure(state, action: PayloadAction<string>) {
            state.isSending = false
            state.isError = true
            state.infoText = action.payload
        },
        resetEstimateDialog: () => initialState,
    }
})

export const {
    estimateDialogOpened,
    sendTextStart,
    sendTextFinish,
    sendTextFailure,
    resetEstimateDialog,
} = slice.actions

export default slice.reducer

export const openEstimateDialog = (repairOrderID: number): AppThunk => async dispatch => {
    try {
        const res = await getRepairOrderByID(repairOrderID)

        const estimate = (() => {
            if(res.CustomerEstimates && res.CustomerEstimates.length > 0){
                return res.CustomerEstimates[0]
            } else {
                throw new Error(`RO ${repairOrderID} does not have an estimate associated with it.`)
            }
        })()

        const workbenchURL = estimate.WorkbenchUrl

        dispatch(estimateDialogOpened({
            repairOrderID,
            roNumber: res.RONumber,
            mpviStatus: res.MPVIStatus,
            workbenchURL: workbenchURL,
            estimate: estimate || undefined,
            customer: res.Customer
        }))
    } catch (error) {
        throw new Error(`The following error occured while trying to open EstimateDialog: ${error}`)
    }
}

export const sendCustomerEstimateLink = (repairOrderID: number, estimateID: number, dealerID: number): AppThunk => async dispatch => {
    try {
        dispatch(sendTextStart())
        await apiFetch(`/api/messages/send-estimate-text`, {
            method: `POST`,
            body: JSON.stringify({
                CustomerEstimateID: estimateID,
                DealerID: dealerID

            })
        })
        await setRepairOrderAttentionNeeded(repairOrderID, false)
        dispatch(updateRepairOrder({
            id: repairOrderID,
            changes: {
                AttentionNeeded: false,
            },
        }))
        dispatch(sendTextFinish())
    } catch (err) {
        let errorMessage = `Unexpected Error!`

        if (typeof err === `object` && err) {
            if (err.hasOwnProperty(`Message`)) {
                errorMessage = err[`Message`]
            }
        }

        dispatch(sendTextFailure(errorMessage))
    }
}