import { GridColDef } from "@mui/x-data-grid";
import { SoldItem } from "api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel";
import React from "react";
import DataTable from "../../../../DataTable";

export interface ISoldByIntervalDataGridDataGridProps {
    data: SoldItem[] 
}

export default function SoldByIntervalDataGrid({
    data
}: ISoldByIntervalDataGridDataGridProps) {
    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          headerName: 'ID',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'interval',
          headerName: 'Interval',
          sortable: false,
          editable: false,
          flex: 1,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'totalSold',
          headerName: 'Total Sold',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .5,
          disableColumnMenu: true
        },
      ];

    const rows = data.map((soldService, index) => {
      let parsedInterval = parseInt(soldService.ItemDescription)
      
      return {
        id: index,
        interval: parsedInterval < 0 ? 'IOLM': `${parsedInterval/1000}K`,
        totalSold: soldService.TotalSold,
      }
    })

    return (
        <DataTable
          rows={rows}
          columns={columns}/>
    )
}