export enum Types {
    PENDING_ESTIMATE,
    PENDING_APPROVAL,
    CUSTOMER_DECLINED,
    CUSTOMER_APPROVED,
}

export enum Status {
    UNDECIDED,
    ACCEPTED,
    DECLINED,
}

export enum Severity {
    SAFETY_CONCERN,
    WARNING,
    OK,
    ADDITIONAL,
    NONE,
}