import { BaseCustomer } from 'api/types/customer'
import { Errors } from 'errors'
import { ApiUtils } from 'api/utils'
import { BaseRepairOrder } from 'api/types/repairOrder'
import { BaseDealer } from 'api/types/dealer'
import { BaseUser } from './user'

enum endpoint {
    SEND_DEFAULT = `SEND_DEFAULT`,
    SEND_ESTIMATE = `SEND_ESTIMATE`,
    GET_BY_CUSTOMER = `GET_BY_CUSTOMER`,
    GET_BY_REPAIR_ORDER =`GET_BY_REPAIR_ORDER`,
    GET_UNREAD = `GET_UNREAD`,
    MARK_READ = `MARK_READ`,
}

const endpoints: {[key in endpoint]: ApiUtils.Endpoint} = {
    SEND_DEFAULT: () => `/api/messages/send-advisor-text`,
    SEND_ESTIMATE: () => `/api/messages/send-estimate-text`,
    GET_BY_CUSTOMER: customerID => `/api/messages/by-customer/${customerID}`,
    GET_BY_REPAIR_ORDER: roID => `/api/messages/by-repair-order/${roID}`,
    GET_UNREAD: () => `/api/messages/unread`,
    MARK_READ: ids => `/api/messages/mark-read`,
}

export interface BaseMessage {
    ID: number
    CustomerEstimateID: number | null
    CustomerID: number
    DealerID: number
    MessageTypeID: number
    MessageProviderID: number
    RepairOrderID: number | null
    UserID: number | null

    Body: string | null
    CreatedAt: string | null
    Direction: number
    From: string | null
    HasAdvisorViewed: boolean
    IsDeleted: boolean
    SentAt: string
    To: string | null
}

export interface Message extends BaseMessage {
    Customer: BaseCustomer
    Dealer: BaseDealer
    RepairOrder: BaseRepairOrder | null
    User: BaseUser | null
}

type DefaultSend = {
    CustomerID: number
    DealerID: number
    MessageBody: string
    RepairOrderID: number | null
    RepairOrderAlertID: number | null
}

type CustomerEstimateSend = {
    CustomerEstimateID: number,
    DealerID: number
}

export const sendDefault = (params: DefaultSend) => ApiUtils.send<Message>(endpoints.SEND_DEFAULT, params, Errors.CRM0012)

export const sendCustomerEstimate = (params: CustomerEstimateSend) => ApiUtils.send<Message>(endpoints.SEND_DEFAULT, params, Errors.CRM0012)

export const getByCustomer = (customerID: number) => ApiUtils.get<Message[]>(() => endpoints.GET_BY_CUSTOMER(customerID), Errors.CRM0014)

export const getByRepairOrder = (roID: number) => ApiUtils.get<Message[]>(() => endpoints.GET_BY_REPAIR_ORDER(roID), Errors.CRM0015)

export const getUnread = () => ApiUtils.get<Message[]>(endpoints.GET_UNREAD, Errors.CRM0017)

export const markRead = (ids: number[], dealerID: number) => ApiUtils.send(endpoints.MARK_READ, {
    MessageIDs: ids,
    DealerID: dealerID
}, Errors.CRM0016, `PATCH`) 