import { useEffect, useRef, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import pages, { IPage } from 'pages'
import { useAppDispatch } from 'store'
import { setViaPage } from 'modules/appNavBar'
import { useAppSelector } from 'store'
import UserPermissionType from 'enums/userPermissionType'
import DealerPermissionType from 'enums/dealerPermissionType'
import { Role } from 'api/types/role'

function useAppPage(page: IPage, isPage: boolean = true) {
    const [isAccessible, setIsAccessible] = useState(true)
    const dispatch = useAppDispatch()
    const {
        EnableBDC,
        EnableMPVI,
    } = useAppSelector(
        state => state.dealer
    )
    const {
        Role: UserRole
    } = useAppSelector(
        state => state.user
    )
    const {
        restrictAccessToUserType: userRoles,
        restrictAccessToDealerType: dealerRoles,
    } = page

    const {
        pathname
    } = useLocation()
    const match = useRouteMatch(page.route)

    const matchRef: any = useRef(match)
    if (JSON.stringify(matchRef.current) !== JSON.stringify(match)) {
        matchRef.current = match
    }

    //handle appNavBar
    useEffect(() => {
        const {
            isExact,
            path,
        } = matchRef.current
        if (isPage && path === page.route) {
            if (isExact) {
                dispatch(setViaPage(page))
            } else {
                dispatch(setViaPage(pages.DEFAULT))
            }
        }
    }, [dispatch, page, pathname, isPage])

    // handle access
    useEffect(() => {
        if (userRoles || dealerRoles) {
            let userHasAccess = false
            if (userRoles) {
                if (UserRole === Role.Advisor && userRoles.includes(UserPermissionType.ADVISOR)) {
                    userHasAccess = true
                }

                if (UserRole === Role.DealerAdmin && userRoles.includes(UserPermissionType.DEALER_ADMIN)) {
                    userHasAccess = true
                }

                if (UserRole === Role.Admin && userRoles.includes(UserPermissionType.ADMIN)) {
                    userHasAccess = true
                }
            }

            if (dealerRoles) {
                if (EnableBDC && dealerRoles.includes(DealerPermissionType.BDC)) {
                    userHasAccess = true
                }

                if (EnableMPVI && dealerRoles.includes(DealerPermissionType.MPVI)) {
                    userHasAccess = true
                }
            }

            setIsAccessible(userHasAccess)
        } else {
            setIsAccessible(true)
        }
    }, [
        userRoles,
        dealerRoles,
        EnableBDC,
        EnableMPVI,
        UserRole
    ])

    return {
        isAccessible
    }
}

export default useAppPage