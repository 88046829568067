import React from 'react'
import {
    Dialog,
    DialogTitle,
} from '@mui/material'
import { useAppSelector, useAppDispatch } from 'store'
import { resetEstimateDialog } from 'modules/estimateDialog'
import LoadingContent from './components/LoadingContent'
import WaitingOnEstimateContent from './components/WaitingOnEstimateContent'
import TextEstimateContent from './components/TextEstimateContent'
import ErrorContent from './components/ErrorContent'
import EstimateList from 'components/EstimateDialog/components/EstimateList'
import { MpviStatus } from 'types/repairOrder'

function EstimateDialog() {
    const dispatch = useAppDispatch()
    const {
        isOpen,
        isLoading,
        isError,
        repairOrderID,
        mpviStatus,
        roNumber,
        estimate,
        customerName,
        workbenchURL
    } = useAppSelector(state => state.estimateDialog)


    const handleClose = () => {
        dispatch(resetEstimateDialog())
    }

    let content
    let title: string = ``

    if (isLoading) {
        title = `Gathering information...`
        content = <LoadingContent />
    }

    if (isError) {
        title = `Something went wrong...`
        content = <ErrorContent
            onClose={handleClose}
        ></ErrorContent>
    }

    if (estimate && repairOrderID) {
        switch (mpviStatus) {
            case MpviStatus.WAITING_ON_ESTIMATE:
                title = `Estimate #${roNumber}`
                content = <WaitingOnEstimateContent
                    repairOrderID={repairOrderID}
                    number={roNumber}
                    onClose={handleClose}
                    workbenchUrl={workbenchURL}
                ></WaitingOnEstimateContent>
                break;
            case MpviStatus.WAITING_ON_APPROVAL:
                title = `Text Estimate To Customer`
                content = <TextEstimateContent
                    onClose={handleClose}
                ></TextEstimateContent>
                break
            default:
                title = `Customer: ${customerName}`
                content = <EstimateList
                    estimate={estimate}
                    onClose={handleClose}
                ></EstimateList>
                break
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            style={{
                visibility: `visible`,
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            {content}
        </Dialog>
    )
}

export default EstimateDialog