export enum Status {
    CLOSED,
    OPEN,
    UNKOWN
}

export enum Transport {
    NONE,
    RENTAL,
    SHUTTLE,
    TOW,
    WAIT,
    UNKNOWN,
}

export enum MpviStatus {
    NOT_STARTED,
    IN_PROGRESS,
    WAITING_ON_ESTIMATE,
    WAITING_ON_APPROVAL,
    MPVI_SENT,
    MPVI_OPENED,
    WAITING_ON_PARTS,
    SERVICE_DECLINED,
    VEHICLE_IN_SERVICE,
    COMPLETE,
    UNKNOWN,
}