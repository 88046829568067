import { useForm } from "react-hook-form";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import YesNoSelect from "componentsUI/YesNoSelect";
import { useAppSelector } from "store";
import { Dealer } from "api/types/dealer";
import pages from "pages";
import { useDispatch } from "react-redux";
import { setDealer } from "modules/dealerForm";
import LoadingIndicator from "components/LoadingIndicator";
import {
  TextMaskedInput,
  NumericMaskedInput,
  TextMaskedInputType,
} from "componentsUI/MaskedInputs";
import styles from "./styles.module.scss";

const Form = forwardRef((_props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      submitForm: () => submitForm(),
    };
  });

  const dispatch = useDispatch();

  const { dealer, isLoading } = useAppSelector((state) => state.dealerForm);

  const title = dealer.ID
    ? pages.DEALERS_EDIT_FORM.title
    : pages.DEALERS_CREATE_FORM.title;

  const [formState, setFormState] = useState<Dealer>();
  const [formLoading, setFormLoading] = useState(isLoading);
  const [hasError, setHastError] = useState(false);

  const changeHandler = (e) => {
    const currentDealer = {
      ...formState,
      [e.target.name]: e.target.value,
    } as Dealer;

    setFormState(currentDealer);
    dispatch(setDealer(currentDealer));
  };

  const onSwitchChangeHandler = (e) => {
    const currentDealer = {
      ...formState,
      [e.target.name]: e.target.checked,
    } as Dealer;
    setFormState(currentDealer);
    dispatch(setDealer(currentDealer));
  };

  useEffect(() => {
    setFormState(dealer);
  }, [dealer]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  useEffect(() => {
    reset(formState);
  }, [formState, reset]);

  useEffect(() => {
    setHastError(isValid);
  }, [isValid]);

  useEffect(() => {
    setFormLoading(isLoading);
  }, [isLoading]);

  const submitForm = () => {
    handleSubmit(onSubmit)();
    return hasError;
  };

  const onSubmit = () => {};

  return (
    <>
      {formLoading ? (
        <LoadingIndicator />
      ) : (
        <form noValidate>
          <h1>{title}</h1>
          <Grid container spacing={2} sx={{ width: "inherited" }}>
            <Grid item xs={6}>
              <TextField
                {...register("ExternalID", { required: true })}
                fullWidth
                label="Activation Key"
                name="ExternalID"
                onChange={changeHandler}
                error={!!errors?.ExternalID}
                disabled={
                  formState?.ID === 0 || formState?.ID === undefined
                    ? false
                    : true
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register("RMDealerID")}
                fullWidth
                label="RM Dealer ID"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                {...register("AutoSendEstimateThresholdInMinutes", {
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: `Please provide a valid number.`,
                  },
                })}
                fullWidth
                type="number"
                onChange={changeHandler}
                className={styles.numberInput}
                label="Auto Send Estimate Threshold In Minutes"
                error={!!errors?.AutoSendEstimateThresholdInMinutes}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("Name", { required: true })}
                fullWidth
                label="Name"
                name="Name"
                onChange={changeHandler}
                error={!!errors?.Name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("Address1")}
                fullWidth
                label="Address Line 1"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("Address2")}
                fullWidth
                label="Address Line 2"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register("City")}
                fullWidth
                id="outlined-required"
                label="City"
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                {...register("Region", {
                  pattern: {
                    value: /^[A-Z]{2}$/i,
                    message: `Please provide a valid state.`,
                  },
                })}
                label="State"
                onChange={changeHandler}
                error={!!errors?.Region}
                value={formState?.Region}
                helperText={errors?.Region?.message}
                InputProps={{
                  inputComponent: TextMaskedInput as any,
                }}
                inputProps={{
                  mask: `aa`,
                  regex: /^[A-Z]{2}$/,
                  type: TextMaskedInputType.State,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericMaskedInput
                format={`#####`}
                name={`PostalCode`}
                label="Postal Code"
                register={register}
                pattern={/^\d{5}$/i}
                handleChange={changeHandler}
                error={!!errors?.PostalCode}
                value={formState?.PostalCode}
                helpText={errors?.PostalCode?.message}
                patternMessage={`Please provide a valid postal code.`}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="time-zone-select-label">Time Zone</InputLabel>
                <Select
                  {...register("TimeZoneID")}
                  fullWidth
                  label="Time Zone"
                  value={formState?.TimeZoneID || ``}
                  onChange={changeHandler}
                >
                  <MenuItem value={`Pacific Standard Time`}>
                    Pacific Standard Time
                  </MenuItem>
                  <MenuItem value={`Mountain Standard Time`}>
                    Mountain Standard Time
                  </MenuItem>
                  <MenuItem value={`Central Standard Time`}>
                    Central Standard Time
                  </MenuItem>
                  <MenuItem value={`Eastern Standard Time`}>
                    Eastern Standard Time
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <NumericMaskedInput
                register={register}
                format={`(###) ###-####`}
                name={`SalesPhoneNumber`}
                label="Sales Phone Number"
                handleChange={changeHandler}
                pattern={/^(\(\d{3}\) ?\d{3}-\d{4}|\d{10})$/}
                error={!!errors?.SalesPhoneNumber}
                value={formState?.SalesPhoneNumber}
                helpText={errors?.SalesPhoneNumber?.message}
                patternMessage={`Please provide a valid phone number.`}
              />
            </Grid>
            <Grid item xs={4}>
              <NumericMaskedInput
                register={register}
                format={`(###) ###-####`}
                name={`ServicePhoneNumber`}
                label="Service Phone Number"
                handleChange={changeHandler}
                pattern={/^(\(\d{3}\) ?\d{3}-\d{4}|\d{10})$/}
                error={!!errors?.ServicePhoneNumber}
                value={formState?.ServicePhoneNumber}
                helpText={errors?.ServicePhoneNumber?.message}
                patternMessage={`Please provide a valid phone number.`}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                register={register}
                name="EnableAdvisorPINs"
                label="Enable Advisor PINs"
                onChange={onSwitchChangeHandler}
                defaultValue={formState?.EnableAdvisorPINs}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                name="EnableAppointments"
                label="Enable Appointments"
                register={register}
                onChange={onSwitchChangeHandler}
                defaultValue={formState?.EnableAppointments}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                name="EnableBDC"
                label="Enable BDC"
                register={register}
                onChange={onSwitchChangeHandler}
                defaultValue={formState?.EnableBDC}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                register={register}
                onChange={onSwitchChangeHandler}
                name="EnableCommunicationDashboard"
                label="Enable Communication Dashboard"
                defaultValue={formState?.EnableCommunicationDashboard}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                name="EnableCRM"
                label="Enable eSS"
                register={register}
                onChange={onSwitchChangeHandler}
                defaultValue={formState?.EnableCRM}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                register={register}
                onChange={onSwitchChangeHandler}
                name="EnableEndUserAdvisorSelection"
                label="Enable End User Advisor Selection"
                defaultValue={formState?.EnableEndUserAdvisorSelection}
              />
            </Grid>
            <Grid item xs={6}>
              <YesNoSelect
                name="EnableMPVI"
                label="Enable MPVI"
                register={register}
                onChange={onSwitchChangeHandler}
                defaultValue={formState?.EnableMPVI}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
});

export default Form;
