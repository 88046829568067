import React from 'react'
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom'
import BdcRouter from 'routers/BdcRouter'
import MPVIContainer from 'containers/MPVIContainer'
import AppRoute from 'components/AppRoute'
import pages from 'pages'
import CustomerSearchView from 'components/CustomerSearchView'
import Dashboard from 'components/Dashboard'
import DealerRouter from 'routers/DealerRouter'
import ReportingRouter from '../ReportingRouter'

function AppRouter() {
    return (
        <Switch>
            <Route path={pages.REPAIR_ORDERS.route}>
                <Dashboard/>
            </Route>
            <AppRoute path={pages.REPORTING.route} page={pages.REPORTING} isBaseRoute>
                <ReportingRouter/>
            </AppRoute>
            <AppRoute path={pages.CUSTOMERS.route} page={pages.CUSTOMERS}>
                <CustomerSearchView/>
            </AppRoute>
            <AppRoute path={pages.BDC.route} page={pages.BDC} isBaseRoute>
                <BdcRouter/>
            </AppRoute>
            <AppRoute path={pages.MPVI.route} page={pages.MPVI}>
                <MPVIContainer/>
            </AppRoute>
            <AppRoute path={pages.DEALERS.route} page={pages.DEALERS} isBaseRoute>
                <DealerRouter />
            </AppRoute>
            <Route path={`*`}>
                <Redirect to={`/dashboard`}/>
            </Route>
        </Switch>
    )
}

export default AppRouter