import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { setDmsSettings, setHasError } from "modules/dealerForm";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "store";

export interface IDmsDealerSetting {
  ID: number;
  DealerID: number;
  DmsProviderID: number;
  DealerCode: string;
  IsActive: boolean;
  IsCheckInSyncEnabled: boolean;
  IsIncrementalSyncEnabled: boolean;
}

const DmsDealerSettings = forwardRef((_props, ref) => {
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => {
    return {
      canSubmit: () => (error === undefined ? false : !error),
    };
  });

  const { dmsDealerSetting, isLoading: stateLoading } = useAppSelector(
    (state) => state.dealerForm
  );

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>();
  const [formState, setFormState] = useState({
    DealerCode: ``,
    IsActive: false,
    DmsProviderID: 0,
    IsCheckInSyncEnabled: false,
    IsIncrementalSyncEnabled: false,
  });

  useEffect(() => {
    setIsLoading(stateLoading);

    let currentSettings = {
      DmsProviderID:
        dmsDealerSetting.DmsProviderID === 0
          ? 1
          : dmsDealerSetting.DmsProviderID,
      DealerCode: dmsDealerSetting.DealerCode,
      IsActive: dmsDealerSetting.IsActive ?? false,
      IsCheckInSyncEnabled: dmsDealerSetting.IsCheckInSyncEnabled ?? false,
      IsIncrementalSyncEnabled:
        dmsDealerSetting.IsIncrementalSyncEnabled ?? false,
    };

    setFormState(currentSettings);

    const error =
      !dmsDealerSetting.DealerCode || dmsDealerSetting.DealerCode === ``
        ? true
        : false;

    dispatch(setHasError(error));
    setError(error);
  }, [dmsDealerSetting, stateLoading, dispatch]);

  const handleCodeChange = (e) => {
    if (!e.target.value || e.target.value === ``) {
      setError(true);
      dispatch(setHasError(true));
    } else {
      setError(false);
      dispatch(setHasError(false));
    }

    const currentSetting = {
      ...formState,
      DmsProviderID: formState?.DmsProviderID,
      DealerCode: e.target.value ?? formState?.DealerCode,
    } as IDmsDealerSetting;

    setFormState(currentSetting);
    dispatch(setDmsSettings(currentSetting));
  };

  const handleActiveChange = (e) => {
    if (!formState.DealerCode || formState.DealerCode === ``) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    let currentSetting = {
      ...formState,
      DmsProviderID: formState?.DmsProviderID,
    } as IDmsDealerSetting;
    setFormState(currentSetting);

    switch (e.target.name) {
      case "IsActive":
        currentSetting.IsActive = e.target.checked;
        break;
      case "IsCheckInSyncEnabled":
        currentSetting.IsCheckInSyncEnabled = e.target.checked;
        break;
      case "IsIncrementalSyncEnabled":
        currentSetting.IsIncrementalSyncEnabled = e.target.checked;
        break;
    }

    dispatch(setDmsSettings(currentSetting));
  };

  const handleDmsProviderChange = (e) => {
    let currentSetting = {
      ...formState,
      DmsProviderID: e.target.value,
    } as IDmsDealerSetting;

    toggleProviderSettings(e.target.value, currentSetting);

    setFormState(currentSetting);
    dispatch(setDmsSettings(currentSetting));
  };

  const toggleProviderSettings = (value, object) => {
    switch (value) {
      case 1: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = true;
        break;
      }
      case 2: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = false;
        break;
      }
      case 3: {
        object.IsCheckInSyncEnabled = true;
        object.IsIncrementalSyncEnabled = true;
      }
    }

    return object;
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form>
          <h1>DMS Dealer Settings</h1>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={formState?.IsActive ?? false}
                      onChange={handleActiveChange}
                    />
                  }
                  label="Active"
                  name="IsActive"
                />
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={formState?.IsCheckInSyncEnabled ?? false}
                      onChange={handleActiveChange}
                    />
                  }
                  label="Enable Check In Sync"
                  name="IsCheckInSyncEnabled"
                />
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ justifyContent: "space-between" }}
                  control={
                    <Switch
                      checked={formState?.IsIncrementalSyncEnabled ?? false}
                      onChange={handleActiveChange}
                    />
                  }
                  label="Enable Incremental Sync"
                  name="IsIncrementalSyncEnabled"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    id="outlined-required"
                    label="Dealer Code"
                    name="DealerCode"
                    value={formState?.DealerCode || ``}
                    onChange={handleCodeChange}
                    error={error}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="dms-provider-select-label">
                      Dms Provider
                    </InputLabel>
                    <Select
                      id="outlined-required"
                      label="Dms Provider"
                      value={formState?.DmsProviderID ?? 1}
                      onChange={handleDmsProviderChange}
                    >
                      <MenuItem value={1}>CDK</MenuItem>
                      <MenuItem value={2}>Reynolds</MenuItem>
                      <MenuItem value={3}>Dealertrack</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
});

export default DmsDealerSettings;
