import { Box, Tooltip, Typography } from "@mui/material";
import { WarrantyAlertRepairOrderResponse } from "api/types/reporting/WarrantyAlertReportingOverview";
import { CollasibleTableRow, TableHeader } from "components/Table";
import { ColumnDefinition } from "components/Table/types";
import React, { useState } from "react";
import formatPrice from "utils/formatPrice";
import trimVin from "utils/trimVin";
import UserRepairOrderLineItemTable from "../UserRepairOrderLineItemTable";

export interface IUserRepairOrderTableProps {
    userRepairOrders: WarrantyAlertRepairOrderResponse[],
    isExpanded: boolean
}

export default function UserRepairOrderTable({
    userRepairOrders,
    isExpanded
}: IUserRepairOrderTableProps){
    const [roExpanded, setRoExpanded] = useState<string>('')

    const handleRepairOrderExpand = (panel: string) => {
        const newValue = roExpanded === panel ? '' : panel
        setRoExpanded(newValue)
    }

    const repairOrderColumnDefinitions: ColumnDefinition[] = [
        {
            content: `RO #`,
            flex: .2
        },
        {
            content: `VIN`,
            flex: .3,
            renderCell: (vin: string) => {
                return (
                    <Tooltip title={vin}>
                        <Typography noWrap sx={{fontSize: '13px'}}>{trimVin(vin)}</Typography>
                    </Tooltip>
                )
            }
        },
        {
            content: `Cured`,
            flex: .25,
            isNumber: true
        },
        {
            content: `Uncured`,
            flex: .25,
            isNumber: true
        },
    ]

    return (
        <Box style={{display: !isExpanded ? 'none' : ''}}>
            <TableHeader columnDefinitions={repairOrderColumnDefinitions} offset={2}/>
            <Box>
                {userRepairOrders.map((ro, index) => {
                    const isExpanded = roExpanded === `ro-${index}`
                    const warrantyWorkTotalDollarAmount = formatPrice(ro.WarrantyWorkTotalDollarAmount)

                    const roRowData = {
                        roNumber: ro.RONumber,
                        vin: ro.VIN,
                        totalCuredDollarAmount: ro.IsCured ? warrantyWorkTotalDollarAmount : 0,
                        totalUncuredDollarAmount: !ro.IsCured ? warrantyWorkTotalDollarAmount : 0
                    }

                    return (
                        <CollasibleTableRow
                            key={`collapsible-user-repair-order-table-row-${index}}`}
                            isExpanded={isExpanded}
                            columnDefinitions={repairOrderColumnDefinitions}
                            rowData={roRowData}
                            offset={2}
                            onClick={() => handleRepairOrderExpand(`ro-${index}`)}>

                            <UserRepairOrderLineItemTable 
                                expanded={isExpanded}
                                lineItems={ro.LineItems}/>
                        </CollasibleTableRow>
                    )
                })}
            </Box>
        </Box>
    )
}