import EstimateNotification from 'api/types/estimateNotification'
import { MpviStatus } from 'types/repairOrder'
import { Signal } from 'types/signalr'

export const mocks = {
    [Signal.REPAIR_ORDER_MPVI_STATUS_UPDATED]: (notification): Partial<EstimateNotification> => {
        return {
            ID: 1,
            RepairOrderID: 1,
            RepairOrderStatus: MpviStatus.IN_PROGRESS,
            RepairOrderAttentionNeeded: false,
        }
    },
    [Signal.REPAIR_ORDER_SYNC_COMPLETED]: (): void => {},
}