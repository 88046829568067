import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { InteractiveMaintenanceByUserReportingOverviewViewModel } from "api/types/reporting/InteractiveMaintenanceReportingOverviewViewModel";
import React from "react";
import { stringToColor } from "utils/stringToColor";
import AvatarIcon from "../../AvatarIcon";
import DataTable from "../../DataTable";

export interface IInteractiveMaintenanceUserStatsDataGridProps {
    data: InteractiveMaintenanceByUserReportingOverviewViewModel[]
}

export default function InteractiveMaintenanceUserStatsDataGrid({
    data
}: IInteractiveMaintenanceUserStatsDataGridProps) {
    const columns: GridColDef[] = [
        { 
          headerClassName: `header-cell`,
          field: 'id',
          headerName: 'ID',
          sortable: false,
          editable: false,
          flex: 0,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'avatar',
          headerName: '',
          sortable: false,
          editable: false,
          flex: .1,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => {
            const columnData = params.formattedValue
            return (
                <AvatarIcon 
                    name={columnData.name} 
                    color={columnData.color}/>
            )
          }
        },
        {
          headerClassName: `header-cell`,
          field: 'advisorName',
          headerName: 'Advisor',
          sortable: false,
          editable: false,
          flex: .5,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'totalPresentations',
          headerName: 'Total Presentations',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'totalCheckIns',
          headerName: 'Total Check-Ins',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true
        },
        {
          headerClassName: `header-cell`,
          field: 'penetration',
          headerName: 'Penetration %',
          type: 'number',
          sortable: false,
          editable: false,
          flex: .25,
          disableColumnMenu: true
        },
      ];

    const rows = data.map((userData, index) => {
      const advisorName = userData.Name
      const color = stringToColor(advisorName)

      return {
        id: index,
        avatar: {
          name: advisorName,
          color: color
        },
        advisorName: advisorName,
        totalPresentations: userData.TotalPresentations,
        totalCheckIns: userData.TotalSubmissions,
        penetration: `${((userData.TotalSubmissions / userData.TotalPresentations) * 100).toFixed(1)}%`,
      }
    })

    return (
        <DataTable
          rows={rows}
          columns={columns} />
    )
}