import React from 'react'
import AppMenu, { IMenuItem } from 'components/AppMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { useAppSelector } from 'store'
import pages from 'pages'
import { Role } from 'api/types/role'

function MainMenu() {
    const {
        EnableBDC,
        EnableMPVI,
    } = useAppSelector(
        state => state.dealer
    )

    const {
        Role: userRole
    } = useAppSelector(
        state => state.user
    )

    const isAdmin = userRole === Role.Admin
    const isDealerAdmin = userRole === Role.DealerAdmin

    const canDisplayReporting = isAdmin || isDealerAdmin
    const canDisplayDealer = isAdmin

    const items: IMenuItem[] = [
        { 
            page: pages.REPAIR_ORDERS,
            visible: true
        },
        {
            page: pages.REPORTING,
            visible: canDisplayReporting,
        },
        { 
            page: pages.CUSTOMERS,
            visible: true
        },
        { 
            page: pages.BDC,
            visible: EnableBDC,
        },
        { 
            page: pages.MPVI,
            visible: EnableMPVI
        },
        {
            page: pages.DEALERS,
            visible: canDisplayDealer
        }
    ]

    return (
        <AppMenu
            icon={<MenuIcon/>}
            menuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }
            }}
            menuItems={items}
        ></AppMenu>
    )
}

export default MainMenu