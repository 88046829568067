import React, { useCallback } from 'react'
import {
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material'
import { Types } from 'types/customerEstimate'
import styles from './styles.module.scss'
import { CustomerEstimate } from 'api/types/CustomerEstimate'

export interface IEstimateList {
    estimate: CustomerEstimate
    onClose(): void
}

function EstimateList({
    estimate: {
        Status,
        EstimateKey
    },
    onClose,
}: IEstimateList) {

    const handlePrint = () => {

        const iframe = document.getElementById('mpvi-view') as HTMLIFrameElement

        if(!!iframe && !!iframe.contentWindow) {

            iframe.contentWindow.postMessage(`print-results`, `${process.env.REACT_APP_MPVI_URL}`);

        }

    }

    const getStatusText = useCallback((): string => {
        switch (Status) {
            case Types.PENDING_ESTIMATE:
                return `Waiting For Estimate`
            case Types.PENDING_APPROVAL:
                return `Waiting On Customer`
            case Types.CUSTOMER_DECLINED:
                return `Customer Declined Service`
            case Types.CUSTOMER_APPROVED:
                return `Customer Approved Service`
            default:
                return `Unknown`
        }
    }, [Status])

    return (
        <>
            <DialogContent dividers className={styles.container}>
                <Typography gutterBottom className={styles.statusText}><span>Status:</span> {getStatusText()}</Typography>
                <iframe id={`mpvi-view`} src={`${process.env.REACT_APP_MPVI_URL}/${EstimateKey}?m=ro`} title={`mpvi-iframe`} className={styles.iframe}></iframe>
            </DialogContent>
            <DialogActions className={styles.buttonsWrapper}>
                <Button 
                    onClick={handlePrint}
                    color={`primary`}
                >Print</Button>
                <Button 
                    autoFocus 
                    onClick={onClose}
                    color={`primary`}
                >Close</Button>
            </DialogActions>
        </>
    )
}

export default EstimateList