export enum Errors {
    CRM0000 = `CRM0000`,
    CRM0002 = `CRM0002`,
    CRM0008 = `CRM0008`,
    CRM0009 = `CRM0009`,
    CRM0010 = `CRM0010`,
    CRM0011 = `CRM0011`,
    CRM0012 = `CRM0012`,
    CRM0013 = `CRM0013`,
    CRM0014 = `CRM0014`,
    CRM0015 = `CRM0015`,
    CRM0016 = `CRM0016`,
    CRM0017 = `CRM0017`,
    CRM0018 = `CRM0018`,
    CRM0019 = `CRM0019`,
    CRM0020 = `CRM0020`,
    CRM0021 = `CRM0021`,
}

class AppError extends Error {
    constructor(message: unknown, type?: Errors) {
        super(errors[type === undefined ? type = Errors.CRM0000 : type](message))
        this.name = `AppError ${Errors[type]}`
    }
}

type IError = (message: unknown) => string

type ErrorsList = {
    [key in Errors]: IError
}

export const errors: ErrorsList = {
    CRM0000: (message) => `Unknown: ${message}`,
    CRM0002: (message) => `Signalr failed to connect with the following error: ${message}`,
    CRM0008: message => `Checking the users auth status produced the following error ${message}`,
    CRM0009: message => `Mapping Error: Invalid API schema retrieved from the API: ${message}`,
    CRM0010: message => `App Initialization failed. Error: ${message}`,
    CRM0011: message => `Error occured while attempting to map a message from the API. Check to make sure schemas are compatable. Error: ${message}`,
    CRM0012: message => `Error sending text message: ${message}`,
    CRM0013: message => `Error sending customer estimate text message: ${message}`,
    CRM0014: message => `Error while attemping to retrieve text messages by customer: ${message}`,
    CRM0015: message => `Error while attemping to retrieve text messages by repair order: ${message}`,
    CRM0016: message => `Error while attempting to mark text messages as read: ${message}`,
    CRM0017: message => `Error while attempting to get unread messages: ${message}`,
    CRM0018: message => `Error occured while attempting to map a customer from the API. Check to make sure schemas are compatable. Error: ${message}`,
    CRM0019: message => `Error occured while attempting to map a customerEstimate from the API. Check to make sure schemas are compatable. Error: ${message}`,
    CRM0020: message => `Error occured while attempting to map a repairOrder from the API. Check to make sure schemas are compatable. Error: ${message}`,
    CRM0021: message => `Error occured while attempting to map a repair order list entity from the API. Check to make sure schemas are compatable. Error: ${message}`

}

export default AppError