import DealerPermissionType from 'enums/dealerPermissionType'
import UserPermissionType from 'enums/userPermissionType'

export enum Pages {
    DEFAULT = `DEFAULT`,
    REPAIR_ORDERS = `REPAIR_ORDERS`,
    REPORTING = `REPORTING`,
    CUSTOMERS = `CUSTOMERS`,
    BDC = `BDC`,
    BDC_DETAIL = `BDC_DETAIL`,
    MPVI = `MPVI`,
    SENT_MESSAGES = `SENT_MESSAGES`,
    RECIEVED_MESSAGES = `RECIEVED_MESSAGES`,
    UNANSWERED_MESSAGES = `UNANSWERED_MESSAGES`,
    AUTOMATED_MESSAGES = `AUTOMATED_MESSAGES`,
    DEALERS = `DEALERS`,
    DEALERS_CREATE_FORM = `DEALERS_CREATE_FORM`,
    DEALERS_EDIT_FORM = `DEALERS_EDIT_FORM`,
}

export interface IPage {
    routeBase?: string,
    route: string
    title?: string
    isPrimaryRoute: boolean
    backRoute?: string
    restrictAccessToDealerType?: DealerPermissionType[]
    restrictAccessToUserType?: UserPermissionType[]
}

type PagesList = {
    [key in Pages]: IPage
}

const pages: PagesList = {
    DEFAULT: {
        route: ``,
        title: `eSS`,
        isPrimaryRoute: true,
    },
    REPAIR_ORDERS: {
        route: `/dashboard`,
        title: `Dashboard`,
        isPrimaryRoute: true,
    },
    REPORTING: {
        route: `/reporting`,
        title: `Reporting`,
        isPrimaryRoute: true,
        restrictAccessToUserType: [
            UserPermissionType.ADMIN,
            UserPermissionType.DEALER_ADMIN
        ],
    },
    CUSTOMERS: {
        route: `/customers`,
        title: `Customers`,
        isPrimaryRoute: true,
    },
    DEALERS: {
        route: `/dealers`,
        title: `Dealers`,
        isPrimaryRoute: true,
        restrictAccessToUserType: [
            UserPermissionType.ADMIN,
        ],
    },
    DEALERS_CREATE_FORM: {
        routeBase: `/dealers/create`,
        route: `/dealers/create`,
        title: `Create Dealer`,
        backRoute: `/dealers`,
        isPrimaryRoute: false,
        restrictAccessToUserType: [
            UserPermissionType.ADMIN,
        ],
    },
    DEALERS_EDIT_FORM: {
        routeBase: `/dealers/edit`,
        route: `/dealers/edit/:id`,
        title: `Edit Dealer`,
        backRoute: `/dealers`,
        isPrimaryRoute: false,
        restrictAccessToUserType: [
            UserPermissionType.ADMIN,
        ],
    },
    BDC: {
        route: `/bdc`,
        title: `BDC`,
        isPrimaryRoute: true,
        restrictAccessToDealerType: [
            DealerPermissionType.BDC,
        ],
    },
    BDC_DETAIL: {
        routeBase: `/bdc/detail`,
        route: `/bdc/detail/:id`,
        title: `BDC`,
        backRoute: `/bdc`,
        isPrimaryRoute: false,
        restrictAccessToDealerType: [
            DealerPermissionType.BDC,
        ],
    },
    MPVI: {
        route: `/mpvi`,
        title: `MPVI`,
        isPrimaryRoute: true,
        restrictAccessToDealerType: [
            DealerPermissionType.MPVI,
        ],
    },
    SENT_MESSAGES: {
        route: `/dashboard/sent-messages`,
        title: `Sent Messages`,
        isPrimaryRoute: true,
    },
    RECIEVED_MESSAGES: {
        route: `/dashboard/received-messages`,
        title: `Received Messages`,
        isPrimaryRoute: true,
    },
    UNANSWERED_MESSAGES: {
        route: `/dashboard/unanswered-messages`,
        title: `Unanswered Messages`,
        isPrimaryRoute: true,
    },
    AUTOMATED_MESSAGES: {
        route: `/dashboard/automated-messages`,
        title: `Automated Messages`,
        isPrimaryRoute: true,
    },
}

export default pages 