import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { WarrantyAlertRecipient } from "api/types/WarrantyAlertRecipient";
import { NumericMaskedInput } from "componentsUI/MaskedInputs";
import { upsertRecipient } from "modules/dealerForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { MaskType, removeMask } from "utils/removeMask";

export interface IWARecipientFormProps {
  waRecipient: WarrantyAlertRecipient;
  open: boolean;
  toggleDialog: () => void;
  index: number;
}

const WARecipientForm = (props: IWARecipientFormProps) => {
  const {
    waRecipients,
    dealer: { ID: dealerID },
  } = useAppSelector((state) => state.dealerForm);

  const dispatch = useDispatch();

  const title =
    props.waRecipient?.ID !== 0 && props.waRecipient?.ID !== undefined
      ? "Edit Recipient"
      : "New Recipient";

  const [formState, setFormState] = useState<WarrantyAlertRecipient>();

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setError,
    formState: { errors },
  } = useForm();

  const changeHandler = (e) => {
    const currentRecipient = {
      ...formState,
      [e.target.name]: e.target.value,
    } as WarrantyAlertRecipient;

    setFormState(currentRecipient);
  };

  const handleClose = () => {
    props.toggleDialog();
    resetField("CellPhone", { defaultValue: "" });
    reset({} as WarrantyAlertRecipient);
  };

  useEffect(() => {
    setFormState(props.waRecipient ?? ({} as WarrantyAlertRecipient));
  }, [props.waRecipient]);

  useEffect(() => {
    reset(formState);
  }, [formState, reset]);

  const onSubmit = (data) => {
    const existingPhone = waRecipients.find(
      (x) => x.CellPhone === removeMask(data.CellPhone, MaskType.Phone)
    );

    if (existingPhone && props.index !== waRecipients.indexOf(existingPhone)) {
      setError("CellPhone", {
        message: "There's a recipient with same phone number for this dealer.",
        type: "custom",
      });
      return;
    }

    dispatch(
      upsertRecipient({
        ...data,
        Index: props.index,
        DealerID: dealerID || 0,
        CellPhone: removeMask(data.CellPhone, MaskType.Phone),
      })
    );
    handleClose();
  };

  return (
    <>
      <Dialog open={props.open ?? false} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack
            sx={{ mt: 1 }}
            component="form"
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              {...register("FirstName", { required: true })}
              fullWidth
              label="First Name"
              name="FirstName"
              onChange={changeHandler}
              error={!!errors?.FirstName}
              value={formState?.FirstName || ``}
            />
            <TextField
              {...register("LastName", { required: true })}
              fullWidth
              label="Last Name"
              name="LastName"
              onChange={changeHandler}
              error={!!errors?.LastName}
              value={formState?.LastName || ``}
            />
            <NumericMaskedInput
              register={register}
              required={true}
              format={`(###) ###-####`}
              name={`CellPhone`}
              label="Cell Phone Number"
              handleChange={changeHandler}
              pattern={/^(\(\d{3}\) ?\d{3}-\d{4}|\d{10})$/}
              error={!!errors?.CellPhone}
              value={formState?.CellPhone || ``}
              helpText={errors?.CellPhone?.message}
              patternMessage={`Please provide a valid phone number.`}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            autoFocus
          >
            Save
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WARecipientForm;
